import { Button, Card, Divider, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import logoZetti from '../assets/images/logoZetti.png';
import { styled } from '@mui/material/styles';
import useAuth from '../Auth/useAuth';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const TimeoutModal = ({ timeoutSession, timeOutMessage }) => {
    const { t } = useTranslation();
    const auth = useAuth();
    const handleClose = () => {
        auth.logout();
    };
    const LogoStyle = styled('div')({
        display: 'flex',
        justifyContent: 'center',
        webkitTransform: 'scale(0.7)',
        transform: 'scale(0.7)'
    });

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary
    }));

    return (
        <Modal
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            open={timeoutSession}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40%' }}>
                <Card sx={{ width: '100%', height: '100%' }}>
                    <Stack>
                        <Item>
                            <LogoStyle>
                                <div>
                                    <img src={logoZetti} alt="" />
                                </div>
                            </LogoStyle>
                        </Item>
                        <Item>
                            <Typography textAlign={'center'} id="modal-modal-title" variant="h4" component="h4">
                                {t(timeOutMessage)}
                            </Typography>
                        </Item>
                        <Item>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <TimelapseIcon sx={{ mb: 2, width: 50, height: 50 }} />
                            </Box>
                            <Divider />
                        </Item>
                        <Item>
                            {' '}
                            <Button variant="contained" sx={{ width: '90%', m: '5%' }} onClick={handleClose}>
                                {' '}
                                {t('w.accept')}{' '}
                            </Button>
                        </Item>
                    </Stack>
                </Card>
            </Box>
        </Modal>
    );
};

export default TimeoutModal;

TimeoutModal.propTypes = {
    timeoutSession: PropTypes.bool,
    timeOutMessage: PropTypes.string
};
