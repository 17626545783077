import FullwidthCard from './fullwidthCard';
import MainCard from 'ui-component/cards/MainCard';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Home = () => {
    const { t } = useTranslation();
    return (
        <MainCard title={t('p.home_page')}>
            <Grid container spacing={3} direction="row" justifyContent="center" alignItems="flex-start">
                <FullwidthCard />
            </Grid>
        </MainCard>
    );
};
export default Home;
