import { env } from '../../env';
const baseUrlApi = env.REACT_APP_API_SERVER;
export const keepAliveRequest = async () => {
    const url = baseUrlApi + 'KeepAlive';
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Accept-Language': 'es'
            }
        });
        return response;
    } catch (error) {
        const response = { status: -1, text: 'Error de comunicación' };
        return response;
    }
};
