import { esES, enUS } from '@mui/x-data-grid';

export const getThemeLang = (lang) => {
    switch (lang) {
        case 'es':
            return esES.components.MuiDataGrid.defaultProps.localeText;
        case 'us':
            return enUS.components.MuiDataGrid.defaultProps.localeText;
        default:
            return enUS.components.MuiDataGrid.defaultProps.localeText;
    }
};
