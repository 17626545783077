import i18n from 'i18n';
import { env } from '../env';
const baseUrlApi = env.REACT_APP_API_SERVER;
const getRegisterColumns = async (idRegisterType) => {
    let lang = 'es';
    i18n.language == 'en' ? (lang = 'us') : null;
    const url = baseUrlApi + 'RegisterType/GetRegisterColumns?idRegisterType=' + idRegisterType;
    let token = JSON.parse(localStorage.getItem('tokens'));
    const myAuthorizationHeader = `Bearer ${token.access_token}`;
    try {
        const response = await fetch(url, {
            method: 'GET',
            // Adding headers to the request
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Accept-Language': lang,
                Authorization: myAuthorizationHeader
            }
        });
        return response;
    } catch (error) {
        let response = { status: -1, text: 'Error de comunicación' };
        return response;
    }
};

export default getRegisterColumns;
