import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import Home from 'views/home';
import PrivateRoute from './PrivateRoute';
import ListCreatedRegisters from '../views/ListCreatedRegisters';

// utilities routing
const CreateUser = Loadable(lazy(() => import('views/users/CreateUsers')));
const AdminUser = Loadable(lazy(() => import('views/users')));
const CreateRegisters = Loadable(lazy(() => import('views/CreateRegisters/Index')));
const ListingRegisters = Loadable(lazy(() => import('views/ListingRegistersRecords')));
const ImportRegisters = Loadable(lazy(() => import('views/ImportRegisters')));
const Reports = Loadable(lazy(() => import('views/reports')));
const CreateNewRecords = Loadable(lazy(() => import('views/CreateNewRecords')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <PrivateRoute rol={['admin']}>
            <MainLayout />
        </PrivateRoute>
    ),
    children: [
        {
            path: '/home',
            element: <Home />
        },
        {
            path: '/users/admin',
            element: <AdminUser />
        },
        {
            path: '/users/admin/createUser',
            element: <CreateUser />
        },
        {
            path: '/padrones/listarPadrones',
            element: <ListingRegisters />
        },
        {
            path: '/padrones/crearPadron',
            element: <CreateRegisters />
        },
        {
            path: '/padrones/importarPadrones',

            element: <ImportRegisters />
        },
        {
            path: '/padrones/crearNuevosRegistros',
            element: <CreateNewRecords />
        },
        {
            path: '/padrones/listarPadronesCreados',
            element: <ListCreatedRegisters />
        },
        {
            path: '/reports',
            element: <Reports />
        }
    ]
};

export default MainRoutes;
