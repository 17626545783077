import { env } from '../../env';
const baseUrlApi = env.REACT_APP_API_SERVER;
export const getSecondaryRegistersList = async (idRegisterType) => {
    const url = baseUrlApi + 'Register/FindBy?idRegisterType=' + idRegisterType;
    let token = JSON.parse(localStorage.getItem('tokens'));
    const myAuthorizationHeader = `Bearer ${token.access_token}`;
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Accept-Language': 'es',
                Authorization: myAuthorizationHeader
            }
        });
        return response;
    } catch (error) {
        let response = { status: -1, text: 'Error de comunicación' };
        return response;
    }
};
