import MainCard from 'ui-component/cards/MainCard';
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
// i18next internationalization
import { useTranslation } from 'react-i18next';

const FullwidthCard = () => {
    const { t } = useTranslation();
    const CardWrapper = styled(MainCard)(({ theme }) => ({
        backgroundColor: theme.palette.primary.light,
        //color: '#fff',
        overflow: 'hidden',
        position: 'relative',
        '&>div': {
            position: 'relative',
            zIndex: 5
        }
    }));

    return (
        <>
            <Grid item>
                <CardWrapper border={false} content={false}>
                    <Grid container justifyContent="space-between" direction="column" margin={2}>
                        <h3>{t('p.select_an_option_from_menu')}</h3>
                    </Grid>
                </CardWrapper>
            </Grid>
        </>
    );
};

export default FullwidthCard;
