import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, Tooltip } from '@mui/material';
import GetRegistersList from 'api-request/register-request/getRegistersList';
import getRegisterColumns from 'api-request/getRegisterColumns';
import { getSecondaryRegistersList } from 'api-request/register-request/getSecondaryRegistersList';
import useSelectorRegister from '../contexts/selectorRegisterContext/useSelectorRegister';
import processResponse from 'api-request/processResponse.js';
import useAlert from 'contexts/alertProvider/useAlert.js';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';

const RegisterSelector = ({ children }) => {
    const alert = useAlert();
    const { t } = useTranslation();
    const { secondaryList, setSecondaryList, registerSelected, changeRegisterType, changeRegisterSelected, changeRegisterColumns } =
        useSelectorRegister();
    const [registerTypeList, setRegisterTypeList] = useState([{ idRegisterType: '0', description: t('w.loading') }]);
    const [secondaryFilterInput, setSecondaryFilterInput] = useState('');
    const errorMesage = t('p.connect_server_failed');

    const handleGetRegistersList = async () => {
        let response = await GetRegistersList();
        return JSON.parse(await processResponse(response, alert, errorMesage));
    };

    const handleGetSecondaryRegistersList = async (idRegisterType) => {
        let response = await getSecondaryRegistersList(idRegisterType);
        return JSON.parse(await processResponse(response, alert, errorMesage));
    };
    const handleGetAndSetRegisterColumns = async (idRegisterType) => {
        let response = await getRegisterColumns(idRegisterType);
        return JSON.parse(await processResponse(response, alert, errorMesage));
    };

    const handleChangeSecondary = (event) => {
        let secondFilter = obtainsSecondFilterRegister(event.target.value);
        changeRegisterType(secondFilter);
        setSecondaryFilterInput(event.target.value);
    };
    const handleChange = async (event) => {
        const registerSelected = obtainsFirstFilterRegister(event.target.value);
        changeRegisterSelected(registerSelected);
        if (event.target.value != t('w.select')) getSecondaryFilterRegisterList(registerSelected.idRegisterType);
        else {
            setVoidInSecondFilter();
            setSecondaryList([{ description: '', idRegister: 0 }]);
        }
        getAndSetRegisterColumns(registerSelected.idRegisterType);
    };

    const getSecondaryFilterRegisterList = async (idFirstFilter) => {
        var secondary = await handleGetSecondaryRegistersList(idFirstFilter);
        await validateTypesList(secondary, setSecondaryList, { description: t('w.select'), idRegister: 0 });
    };

    const getAndSetRegisterColumns = async (idRegisterType) => {
        var columnData = await handleGetAndSetRegisterColumns(idRegisterType);
        changeRegisterColumns(columnData);
    };

    const getRegistersType = async () => {
        var registersTypeList = await handleGetRegistersList();
        await validateTypesList(registersTypeList, setRegisterTypeList, { description: t('w.select'), idRegisterType: 0 });
    };

    const validateTypesList = async (typesList, setListFunction, defaultValue) => {
        if (typesList != null) {
            typesList.push(defaultValue);
            await setListFunction(typesList);
        } else {
            processError();
        }
    };

    const obtainsSecondFilterRegister = (secondChoise) => {
        let secondFilter = secondaryList.find((option) => {
            return secondChoise == option.description;
        });
        return secondFilter;
    };
    const processError = () => {
        setRegisterTypeList([{ idRegisterType: '0', description: t('w.error') }]);
        setSecondaryList([{ idRegister: '0', description: t('w.error') }]);
    };

    const setVoidInSecondFilter = async () => {
        changeRegisterType({ description: t('w.select'), idRegister: 0 });
        setSecondaryList([{ description: t('w.select'), idRegister: 0 }]);
        setSecondaryFilterInput(t('w.select'));
    };

    const obtainsFirstFilterRegister = (primeraSeleccion) => {
        let primerFiltro = registerTypeList.find((option) => {
            return primeraSeleccion == option.description;
        });
        return primerFiltro;
    };

    useEffect(() => {
        getRegistersType();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (registerSelected.description != '') setVoidInSecondFilter();
        else setSecondaryFilterInput('');
        //eslint-disable-next-line
    }, [registerSelected]);

    useEffect(() => {
        return () => {
            changeRegisterSelected({ description: '', idRegister: 0 });
            setSecondaryList([{ description: '', idRegister: 0 }]);
        };
        //eslint-disable-next-line
    }, []);

    const registerListOptions = registerTypeList?.map((option) => {
        return (
            <MenuItem key={option.idRegisterType} value={option.description}>
                {option.description}
            </MenuItem>
        );
    });

    const secondaryListOptions = secondaryList.map((option) => {
        return (
            <MenuItem key={option.idRegister} value={option.description}>
                <Tooltip title={`Id: ${option.idRegister}`} placement="right">
                    <Box sx={{ width: '100%', height: '100%' }}>{option.description}</Box>
                </Tooltip>
            </MenuItem>
        );
    });

    return (
        <div sx={{ marginBottom: 4, float: 'right' }}>
            <FormControl sx={{ marginLeft: 1, marginRight: 1, marginBottom: 4, minWidth: 150 }}>
                <InputLabel variant="outlined" htmlFor="uncontrolled-native">
                    {t('w.principal')}
                </InputLabel>
                <Select
                    input={<OutlinedInput label={t('w.principal')} />}
                    onChange={handleChange}
                    value={registerSelected.description}
                    inputProps={{
                        name: 'Type of Register',
                        id: 'Type-of-Register-Selector'
                    }}
                >
                    {registerListOptions}
                </Select>
            </FormControl>
            <FormControl id="seleccionable" sx={{ marginLeft: 1, marginRight: 1, marginBottom: 4, minWidth: 150 }}>
                <InputLabel variant="outlined" htmlFor="uncontrolled-native">
                    {t('w.secondary')}
                </InputLabel>
                <Select
                    id="id"
                    input={<OutlinedInput label={t('w.secondary')} />}
                    inputProps={{
                        name: 'Secondary filter',
                        id: 'Secondary-filter-Selector'
                    }}
                    onChange={handleChangeSecondary}
                    value={secondaryFilterInput}
                >
                    {secondaryListOptions}
                </Select>
            </FormControl>
            <FormControl id="children" sx={{ marginLeft: 1, marginRight: 1, marginBottom: 4 }}>
                {children}
            </FormControl>
        </div>
    );
};
export default RegisterSelector;
RegisterSelector.propTypes = {
    children: PropTypes.node
};
