import RegisterSelector from 'utils/RegisterSelector';
import MainCard from 'ui-component/cards/MainCard';
import { useTranslation } from 'react-i18next';
import { FormControl } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import useSelectorRegister from '../../contexts/selectorRegisterContext/useSelectorRegister';
import { useState, useEffect } from 'react';
import LoadedRegistersTable from './LoadedRegistersTable.js';
import ListCreatedRegistersRequest from 'api-request/register-request/ListCreatedRegistersRequest';
import processResponse from 'api-request/processResponse';
import useAlert from 'contexts/alertProvider/useAlert';

const ListCreatedRegisters = () => {
    const { t } = useTranslation();
    const [registers, setRegisters] = useState([]);
    const selectorRegister = useSelectorRegister();
    const [isLoading, setLoading] = useState(false);
    const alert = useAlert();

    const errorMesage = t('p.connect_server_failed');
    const handleListCreatedRegistersRequest = async () => {
        let response = await ListCreatedRegistersRequest(selectorRegister.registerType);
        return JSON.parse(await processResponse(response, alert, errorMesage));
    };

    const handleListRegisters = async () => {
        setLoading(true);
        var response = await handleListCreatedRegistersRequest();
        if (response) {
            setRegisters(await response);
        }
        setLoading(false);
    };

    useEffect(() => {
        setRegisters([]);
    }, [selectorRegister.registerSelected]);

    return (
        <MainCard title={t('p.list_registers')}>
            <div sx={{ marginBottom: 4 }}>
                <FormControl sx={{ marginRight: 1, marginBottom: 2, marginTop: 2, float: 'left' }}>
                    <RegisterSelector />
                </FormControl>
                <FormControl
                    sx={{
                        marginLeft: 1,
                        marginRight: 1,
                        marginBottom: 2,
                        marginTop: 2,
                        float: {
                            xs: 'left',
                            sm: 'right'
                        }
                    }}
                >
                    <LoadingButton
                        disabled={selectorRegister.registerType == 'void' || selectorRegister.registerType.idRegister == 0}
                        onClick={handleListRegisters}
                        loading={isLoading}
                        size="large"
                        variant="contained"
                        sx={{ marginLeft: 1, marginRight: 1, marginTop: 1, float: 'right', width: 180 }}
                    >
                        {t('p.list_registers')}
                    </LoadingButton>
                </FormControl>
            </div>
            <LoadedRegistersTable registers={registers ? registers : null} handleListRegisters={handleListRegisters} />
        </MainCard>
    );
};

export default ListCreatedRegisters;
