import i18n from 'i18n';
import selectRegisterType from './selectRegisterType';
import { env } from '../../env';
const baseUrlApi = env.REACT_APP_API_SERVER;

const deleteRegisterRequest = async (register) => {
    let lang = 'es';
    i18n.language == 'en' ? (lang = 'us') : null;
    const url = baseUrlApi + selectRegisterType(register.id_register_type) + '/Delete?idRegisterName=' + register.id_register_name;

    const bodyRequest = {
        //idRegisterType: register.id_register_type,
        idRegister: register.id_register,
        dateFrom: register.date_from,
        dateTo: register.date_to,
        name: register.name
    };

    let token = JSON.parse(localStorage.getItem('tokens'));
    const myAuthorizationHeader = `Bearer ${token.access_token}`;

    try {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Accept-Language': lang,
                Authorization: myAuthorizationHeader
            },
            body: JSON.stringify(bodyRequest)
        });
        return response;
    } catch (error) {
        let response = { status: -1, text: 'Error de comunicación' };
        return response;
    }
};

export default deleteRegisterRequest;
