import { Navigate } from 'react-router';
import PropTypes from 'prop-types';
import useAuth from 'Auth/useAuth';

function PrivateRoute({ children }) {
    const auth = useAuth();
    return auth.isLogged() ? children : <Navigate to={{ pathname: '/auth/login', state: { from: location } }} />;
}

PrivateRoute.propTypes = {
    rol: PropTypes.array.isRequired,
    children: PropTypes.element.isRequired
};

export default PrivateRoute;
