import PropTypes from 'prop-types';
import useSelectorRegister from 'contexts/selectorRegisterContext/useSelectorRegister';
import { createContext, useState } from 'react';

export const ListingRecordsContext = createContext();

const ListingRecordsProvider = ({ children }) => {
    const selectorRegister = useSelectorRegister();
    const [rowsState, setRowsState] = useState({
        page: 0,
        pageSize: 10
    });
    const [date, setDate] = useState(new Date());
    const [registers, setRegisters] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [alertMessage, setAlert] = useState('');
    const [totalItems, setTotalItems] = useState(0);
    const [used, setUsed] = useState([]);
    const [renderedFilters, setRenderedFilters] = useState([]);
    const [filter, setFilter] = useState({});

    const setFilterStructure = async () => {
        let object = {};
        let columnsKeys = selectorRegister.registerColumns.map((columnInfo) => {
            return columnInfo.field;
        });
        for (let i = 0; i < columnsKeys.length; i++) {
            let key = columnsKeys[i];
            object[key] = '';
        }
        setFilter(object);
    };

    const flushRegisters = () => {
        setRegisters([]);
        setTotalItems(0);
        setDate(date);
        setRowsState({
            page: 0,
            pageSize: 10
        });
        setUsed([]);
        setRenderedFilters([]);
        setFilterStructure();
    };

    const setRegistersRecords = async (result) => {
        if (result) {
            setLoading(true);
            setRegisters(result.answer);
            setTotalItems(result.totalItems);
            setLoaded(true);
            setLoading(false);
            setLoading(false);
        }
    };

    const contextValue = {
        filter,
        registers,
        loaded,
        isLoading,
        alertMessage,
        totalItems,
        rowsState,
        used,
        renderedFilters,
        date,
        setRegistersRecords,
        setFilterStructure,
        setRenderedFilters,
        setUsed,
        setLoaded,
        setRowsState,
        setDate,
        setRegisters,
        setLoading,
        setAlert,
        setTotalItems,
        flushRegisters,
        modifyFilter({ field, value }) {
            if (field != '') {
                filter[field] = value;
                setFilter(filter);
            }
        },
        deleteFilter(field) {
            setRenderedFilters(
                renderedFilters.filter((filter) => {
                    return filter.props.field !== field;
                })
            );
        }
    };
    return <ListingRecordsContext.Provider value={contextValue}>{children}</ListingRecordsContext.Provider>;
};

ListingRecordsProvider.propTypes = {
    children: PropTypes.element.isRequired
};

export default ListingRecordsProvider;
