import { keycloakUrl } from 'api-request/urlConfig';
import { env } from '../../env';
const baseUrlKeycloak = env.REACT_APP_KEYCLOAK_SERVER;
export const expiredCheckRequest = async () => {
    const url = baseUrlKeycloak + keycloakUrl + 'users/';
    let token = JSON.parse(localStorage.getItem('tokens'));
    const myAuthorizationHeader = `Bearer ${token.access_token}`;
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Accept-Language': 'es',
                Authorization: myAuthorizationHeader
            }
        });
        return response;
    } catch (error) {
        const response = { status: -1, text: 'Error de comunicación' };
        return response;
    }
};
