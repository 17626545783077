const processResponse = async (response, alert, errorMesage, alertSuccess) => {
    if (response.status === 200 || response.status === 204) {
        if (alertSuccess) alert.success(alertSuccess);
        let res = await response.text();
        return res ? res : 'ok';
    } else if (response.status == -1) {
        if (response.text) console.log(response.text);
        alert.error(errorMesage);
    } else {
        var data = await response.text();
        data ? (data = JSON.parse(await data)) : (data = { message: response.status + ' ' + errorMesage });
        if (data.message) alert.error(data.message);
        else if (data.error_description) alert.error(data.error_description); //para respuestas de keycloack
        else if (data.error) alert.error('Error: ' + data.status + ' ' + data.error + '. from ' + response.url);
        else {
            alert.error('Error');
            console.log( JSON.stringify(await data));
        }
    }
    return null;
};

export default processResponse;
