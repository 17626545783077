import { createContext, useState } from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import i18n from 'i18n';
import jwt from 'jwt-decode';
import LogoutRequest from 'api-request/auth-request/logoutRequest';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

export const AuthContext = createContext();

const paths = ["/home", "/users/admin", "/users/admin/createUser", "/padrones/listarPadrones", "/padrones/crearPadron", "/padrones/importarPadrones", "/padrones/crearNuevosRegistros", "/padrones/listarPadronesCreados", "/reports"]

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const aux = JSON.parse(localStorage.getItem('user'));
        if (aux) {
            setUser(aux);
        }
    }, []);

    useEffect(() => {
        const lastPath = localStorage.getItem('lastPath');
        //console.log(lastPath)
        if (user) {
            if (lastPath && paths.find(p => p === lastPath)) {
                navigate(lastPath);
            } else {
                navigate('auth/login');
            }
        } else {
            navigate('auth/login');
        }
    }, [user, navigate]);

    const contextValue = {
        user,
        login(tokens) {
            let decriptUser = jwt(tokens.access_token);
            if (decriptUser.realm_access.roles.includes('admin_role')) {
                setUser(decriptUser);
                decriptUser.language ? i18n.changeLanguage(decriptUser.language) : i18n.changeLanguage('en');
                localStorage.setItem('user', JSON.stringify(decriptUser));
                localStorage.setItem('tokens', JSON.stringify(tokens));
            } else {
                throw new Error(t('p.not_have_administrator_role'));
            }
        },
        logout() {
            setUser(null);
            let result = LogoutRequest();
            localStorage.removeItem('user');
            localStorage.removeItem('tokens');
            return result;
        },
        isLogged() {
            return user != null;
        },

        isAllowed(rights) {
            return rights.some((right) => user.rights.includes(right));
        },
        hasRole(roles) {
            return roles.some((rol) => user.roles.includes(rol));
        }
    };
    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
    children: PropTypes.element.isRequired
};

export default AuthProvider;
