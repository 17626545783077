import { useState, createContext } from 'react';
import PropTypes from 'prop-types';

export const AlertContext = createContext();
AlertContext.displayName = 'AlertContext';

const AlertProvider = ({ children }) => {
    const [alert, setAlert] = useState('none');
    const [alertText, setAlertText] = useState('Alert!');
    const [alertSeverity, setAlertSeverity] = useState('error');
    const constextValue = {
        alert: alert,
        alertText: alertText,
        alertSeverity: alertSeverity,
        success: (text) => {
            setAlertText(text);
            setAlert('success');
            setAlertSeverity('success');
        },
        warning: (text) => {
            setAlertText(text);
            setAlert('warning');
            setAlertSeverity('warning');
        },
        error: (text) => {
            setAlertText(text);
            setAlert('error');
            setAlertSeverity('error');
        },
        clear: () => setAlert('none')
    };
    return <AlertContext.Provider value={constextValue}>{children}</AlertContext.Provider>;
};

AlertProvider.propTypes = {
    children: PropTypes.array.isRequired
};

export default AlertProvider;
