import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CustomNoRowsOverlay from 'utils/CustomNoRowsOverlay';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import RegisterModal from './RegisterModal';
import deleteRegisterRequest from 'api-request/register-request/deleteRegisterRequest';
import i18next from 'i18next';
import processResponse from 'api-request/processResponse';
import useAlert from 'contexts/alertProvider/useAlert';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Dialog } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/system';
import { getThemeLang } from 'utils/getThemeLang.js';
import { useTranslation } from 'react-i18next';

export default function LoadedRegistersTable(registers) {
    const alert = useAlert();
    const [pageSize, setPageSize] = useState(5);
    const themeLang = getThemeLang(i18next.language);
    const theme = createTheme(getThemeLang(i18next.language));
    const { t } = useTranslation();
    const [hoveredRow, setHoveredRow] = React.useState(undefined);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [erasing, setErasing] = React.useState(false);
    const [registerIds, setRegisterIds] = React.useState({});
    const [modifyDialogOpen, setModifyDialogOpen] = React.useState(false);
    const [registersToRender, setRegistersToRender] = useState([...registers.registers]);

    useEffect(() => {
        setRegistersToRender([...registers.registers]);
    }, [registers]);

    const cols = [
        { headerName: t('w.name'), field: 'name', minWidth: 150, flex: true },
        { headerName: t('p.creation_date'), minWidth: 150, field: 'creation_date', flex: true },
        { headerName: t('p.creation_user'), minWidth: 120, field: 'creation_user', flex: true },
        { headerName: t('p.date_since'), minWidth: 150, field: 'date_from', flex: true },
        { headerName: t('p.date_until'), minWidth: 150, field: 'date_to', flex: true },
        { headerName: t('p.number_records'), minWidth: 150, field: 'number_records', flex: true },
        {
            field: 'actions',
            headerName: '',
            minWidth: 60,
            sortable: false,
            disableColumnMenu: true,
            disableColumnSelector: true,
            hideable: false,
            flex: true,
            renderCell: (params) => {
                if (hoveredRow === params.id) {
                    return (
                        <Box
                            sx={{
                                width: '100%',
                                justifyContent: 'left',
                                alignItems: 'center'
                            }}
                        >
                            <IconButton size="small" onClick={() => setDialogOpen(true)}>
                                <DeleteIcon fontSize="inherit" />
                            </IconButton>
                            <IconButton size="small" onClick={() => setModifyDialogOpen(true)}>
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                        </Box>
                    );
                } else return undefined;
            }
        },
        { headerName: t('p.register_id'), field: 'id_register', minWidth: 150, flex: true },
        { headerName: t('p.register_name_id'), field: 'id_register_name', minWidth: 150, flex: true },
        { headerName: t('p.register_type_id'), field: 'id_register_type', minWidth: 150, flex: true },
        { headerName: t('p.modification_date'), field: 'modification_date', minWidth: 150, flex: true },
        { headerName: t('p.modification_user'), field: 'modification_user', minWidth: 150, flex: true }
    ];

    const onMouseEnterRow = (event) => {
        const id = Number(event.currentTarget.getAttribute('data-id'));
        setHoveredRow(id);
    };

    const onMouseLeaveRow = () => {
        setHoveredRow(undefined);
    };

    const handleRegisterModalClose = () => {
        setModifyDialogOpen(false);
    };

    const handleDialogClose = () => {
        setErasing(false);
        setDialogOpen(false);
        setRegisterIds({});
    };

    const handleDeleteRegister = async () => {
        setErasing(true);
        let response = await deleteRegisterRequest(registerIds);
        if (response.status === 200 || response.status === 204) {
            registers.handleListRegisters();
        }
        setErasing(false);
        setDialogOpen(false);
        return await processResponse(response, alert, t('p.connect_server_failed'), t('p.register_delete_success'));
    };

    var rowId = 0;
    registersToRender.forEach((element) => {
        element.rowId = rowId;
        rowId++;
        return element;
    });

    return (
        <div style={{ display: 'flex', height: '100%', width: '100%' }}>
            <Box
                sx={{
                    minHeight: '30vw',
                    width: '100%',
                    '& .super-app-theme--header': {
                        backgroundColor: '#c8e4fb'
                    },
                    '& .MuiDataGrid-columnSeparator--sideRight': {
                        backgroundColor: '#c8e4fb',
                        color: '#c8e4fb'
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: '#c8e4fb'
                    },
                    flexGrow: 1
                }}
            >
                <ThemeProvider theme={theme}>
                    <DataGrid
                        autoHeight={registers.registers.length > 0 ? true : false}
                        slots={
                            {
                                toolbar: GridToolbar,
                                noRowsOverlay: CustomNoRowsOverlay
                            }
                        }
                        key={(row) => row.rowId}
                        getRowId={(row) => row.rowId}
                        rows={registersToRender}
                        columns={cols}
                        initialState={{
                            columns: {
                                cols,
                                columnVisibilityModel: {
                                    id_register: false,
                                    id_register_name: false,
                                    id_register_type: false,
                                    modification_date: false,
                                    modification_user: false
                                }
                            },
                            pagination: { paginationModel: { pageSize: pageSize } }
                        }}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        pageSizeOptions={[5, 10, 20]}
                        pagination
                        localeText={themeLang}
                        slotProps={{
                            row: {
                                onMouseEnter: onMouseEnterRow,
                                onMouseLeave: onMouseLeaveRow
                            }
                        }}
                        onRowSelectionModelChange={(ids) => {
                            if (ids.length) {
                                setRegisterIds(registersToRender[ids]);
                            }
                        }}
                    />
                    <Dialog open={dialogOpen} onClose={handleDialogClose}>
                        <DialogTitle sx={{ fontSize: 18 }} id="alert-dialog-title">
                            {t('w.confirmation')}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText sx={{ fontSize: 16 }} id="alert-dialog-description">
                                {t('p.the_register') + ' ' + registerIds.name + ' ' + t('p.will_be_deleted_confirm')}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <LoadingButton
                                loading={erasing}
                                loadingPosition="start"
                                startIcon={<DeleteIcon />}
                                sx={{ margin: 2 }}
                                variant="contained"
                                size="large"
                                onClick={handleDeleteRegister}
                            >
                                {t('w.delete')}
                            </LoadingButton>
                            <Button size="large" onClick={handleDialogClose} sx={{ margin: 2 }} variant="contained">
                                {t('w.cancel')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <RegisterModal
                        rowInfo={registerIds}
                        modal={modifyDialogOpen}
                        handleClose={handleRegisterModalClose}
                        handleListRegisters={registers.handleListRegisters}
                    />
                </ThemeProvider>
            </Box>
        </div>
    );
}

DataGrid.defaultProps = {
    rows: PropTypes.isNotRequired
};

i18next.on('languageChanged', function () {
    LoadedRegistersTable.themeLang = getThemeLang(i18next.language);
});
