import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const SelectorRegisterContext = createContext();

const SelectorRegisterProvider = ({ children }) => {
    const [registerType, setRegisterType] = useState({ description: 'void', idRegisterType: 0, idRegister: 0 });
    const [registerSelected, setRegisterSelected] = useState({ description: '', idRegister: 0 });
    const [selectionAvailable, setSelectionAvailable] = useState(false);
    const [registerColumns, setRegisterColumns] = useState([]);
    const [secondaryList, setSecondaryList] = useState([{ idRegister: '0', description: '' }]);

    const contextValue = {
        registerType,
        secondaryList,
        registerSelected,
        selectionAvailable,
        registerColumns,
        setSecondaryList,
        setRegisterColumns,
        setSelectionAvailable,
        changeRegisterType(registerType) {
            setRegisterType(registerType);
        },
        changeRegisterSelected(register) {
            setRegisterSelected(register);
        },
        changeRegisterColumns(registerColumns) {
            setRegisterColumns(registerColumns);
        }
    };
    return <SelectorRegisterContext.Provider value={contextValue}>{children}</SelectorRegisterContext.Provider>;
};

SelectorRegisterProvider.propTypes = {
    children: PropTypes.element.isRequired
};

export default SelectorRegisterProvider;
