import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import i18next from 'i18next';

const PersonIcon = () => {
    var exportador = <PersonOutlineTwoToneIcon sx={{ color: '#42a5f5' }} fontSize={'small'} />;
    return exportador;
};
const ReportIcon = () => {
    var exportador = <AssessmentTwoToneIcon sx={{ color: '#42a5f5' }} fontSize={'small'} />;
    return exportador;
};
const RegisterIcon = () => {
    var exportador = <ListAltTwoToneIcon sx={{ color: '#42a5f5' }} fontSize={'small'} />;
    return exportador;
};
const HomeIcon = () => {
    var exportador = <HomeTwoToneIcon sx={{ color: '#42a5f5' }} fontSize={'small'} />;
    return exportador;
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: 'Menu',
    type: 'group',
    children: [
        {
            id: 'home',
            title: 'Home2',
            type: 'item',
            url: '/home',
            icon: HomeIcon,
            breadcrumbs: false
        },
        {
            id: 'users',
            title: 'Usuarios2',
            type: 'collapse',
            url: '/users',
            icon: PersonIcon,
            children: [
                {
                    id: 'crearUsuarios',
                    title: 'Crear Usuarios',
                    type: 'item',
                    url: '/users/admin',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'reports',
            title: 'Reportes',
            type: 'item',
            url: '/reports',
            icon: ReportIcon,
            breadcrumbs: false
        },
        {
            id: 'padrones',
            title: 'Padrones',
            type: 'collapse',
            icon: RegisterIcon,
            children: [
                {
                    id: 'importarPadrones',
                    title: 'Importar Padrones',
                    type: 'item',
                    url: '/padrones/importarPadrones',
                    breadcrumbs: false
                },
                {
                    id: 'listarPadronesCreados',
                    title: 'Listar Padrones',
                    type: 'item',
                    url: '/padrones/listarPadronesCreados',
                    breadcrumbs: false
                },
                {
                    id: 'listarRegistrosDePadrones',
                    title: 'Listar Registros De Padrones',
                    type: 'item',
                    url: '/padrones/listarPadrones',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

i18next.on('languageChanged', function () {
    utilities.title = i18next.t('w.menu');
    utilities.children[0].title = i18next.t('w.home');
    utilities.children[1].title = i18next.t('w.user');
    utilities.children[1].children[0].title = i18next.t('p.admin_users');
    utilities.children[2].title = i18next.t('w.reports');
    utilities.children[3].title = i18next.t('w.registers');
    utilities.children[3].children[0].title = i18next.t('p.import_register');
    utilities.children[3].children[1].title = i18next.t('p.list_registers');
    utilities.children[3].children[2].title = i18next.t('p.list_records_of_register');
});

export default utilities;
