import { env } from '../../env';
const baseUrlKeycloak = env.REACT_APP_KEYCLOAK_SERVER;

const LogoutRequest = async () => {
    const qs = require('qs');
    const refreshToken = JSON.parse(localStorage.getItem('tokens')).refresh_token;
    const dataBody = {
        refresh_token: refreshToken,
        client_id: 'Sistema-de-Padrones-Front'
    };
    const url = baseUrlKeycloak + 'realms/Sistema-de-Padrones/protocol/openid-connect/logout';
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: qs.stringify(dataBody) // JSON.stringify(dataBody)
        });
        return response;
    } catch (error) {
        let response = { status: -1, text: 'Error de comunicación' };
        return response;
    }
};
export default LogoutRequest;
