import { parseISO } from 'date-fns';
import { Button, Divider, FormControl, Modal, Box, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSelectorRegister from 'contexts/selectorRegisterContext/useSelectorRegister';
import RegisterModalInputs from './RegisterModalInputs';
import { DatePicker } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';
import { useState, useEffect } from 'react';
import { useTheme } from '@emotion/react';
import useAlert from 'contexts/alertProvider/useAlert';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Dialog } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import modifyRegisterRequest from 'api-request/register-request/modifyRegisterRequest';
import processResponse from 'api-request/processResponse';
import PropTypes from 'prop-types';

export default function RegisterModal(props) {
    const [since, setSince] = useState(new Date(parseISO(props.rowInfo.date_from)));
    const [until, setUntil] = useState(new Date(parseISO(props.rowInfo.date_to)));
    const [editLoading, setEditLoading] = useState(false);
    const [disableEdit, setDisableEdit] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const { t } = useTranslation();
    const selection = useSelectorRegister();
    const theme = useTheme();
    const alert = useAlert();

    const style = {
        position: 'absolute',
        left: '50%',
        height: '80%',
        marginTop: '1%',
        transform: 'translate(-50%, 0%)',
        bgcolor: 'background.paper',
        backgroundColor: '#FFFFFF',
        boxShadow: 24,
        p: 2,
        padding: '10px',
        borderRadius: 3
    };
    const titleStyle = {
        margin: 2,
        marginLeft: 4,
        marginBotoom: 3,
        fontWeight: 500,
        color: '#000',
        fontSize: 18
    };

    const subtitleStyle = {
        margin: 1,
        marginLeft: 3,
        fontWeight: 400,
        color: '#000',
        fontSize: 14
    };

    const fieldsAndTraductions = [
        { field: 'name', trans: t('w.name') },
        { field: 'date_from', trans: t('p.date_since') },
        { field: 'date_to', trans: t('p.date_until') },
        { field: 'creation_date', trans: t('p.creation_date') },
        { field: 'creation_user', trans: t('p.creation_user') },
        { field: 'modification_date', trans: t('p.modification_date') },
        { field: 'modification_user', trans: t('p.modification_user') },
        { field: 'id_register_type', trans: t('p.register_type_id') },
        { field: 'id_register', trans: t('p.register_id') },
        { field: 'id_register_name', trans: t('p.register_name_id') },
        { field: 'number_records', trans: t('p.number_records')},
        { field: 'rowId', trans: 'rowId' }
    ];

    useEffect(() => {
        setSince(parseISO(props.rowInfo.date_from));
        setUntil(parseISO(props.rowInfo.date_to));
        setDisableEdit(true);
    }, [props.rowInfo]);

    const handleDialogClose = () => {
        setDialogOpen(false);
        setDisableEdit(true);
        setEditLoading(false);
        setSince(parseISO(props.rowInfo.date_from));
        setUntil(parseISO(props.rowInfo.date_to));
    };

    const handleModifyRegister = async () => {
        setEditLoading(true);
        let response = await modifyRegisterRequest(props.rowInfo, since, until);
        if (response.status === 200 || response.status === 204) {
            setDialogOpen(false);
            setDisableEdit(true);
            props.handleListRegisters();
            props.handleClose();
        }
        setDialogOpen(false);
        setEditLoading(false);
        return await processResponse(response, alert, t('p.connect_server_failed'), t('p.register_modify_success'));
    };

    const handleEdit = () => {
        setDialogOpen(true);
    };

    const handleModalClose = () => {
        setSince(parseISO(props.rowInfo.date_from));
        setUntil(parseISO(props.rowInfo.date_to));
        setDialogOpen(false);
        setDisableEdit(true);
        props.handleClose();
    };

    return (
        <Modal
            sx={{ width: '100%', height: 'auto', display: 'flex', justifyContent: 'center' }}
            keepMounted
            open={props.modal}
            onClose={handleModalClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box sx={style}>
                <Typography sx={titleStyle}>{props.rowInfo.name + ' ' + t('p.register_detail')}</Typography>
                <Divider />
                <Box sx={{ overflowY: 'auto', height: '78%', marginTop: 2 }}>
                    <Box sx={{ marginLeft: 1 }}>
                        <Typography sx={subtitleStyle}>{t('p.register_type')}</Typography>
                        <TextField
                            id="PadronTypeID"
                            variant="outlined"
                            label={t('w.principal')}
                            size="small"
                            style={{ height: 38 }}
                            value={selection.registerSelected.description}
                            InputProps={{
                                readOnly: true
                            }}
                            InputLabelProps={{ shrink: true }}
                            sx={{ margin: 2, width: '40%', minWidth: 150 }}
                        ></TextField>
                        <TextField
                            id="PadronID"
                            variant="outlined"
                            label={t('w.secondary')}
                            size="small"
                            style={{ height: 38 }}
                            value={selection.registerType.description}
                            InputProps={{
                                readOnly: true
                            }}
                            InputLabelProps={{ shrink: true }}
                            sx={{ margin: 2, width: '40%', minWidth: 150, marginBottom: 4 }}
                        ></TextField>
                    </Box>
                    <Box sx={{ marginLeft: 1 }}>
                        <Typography sx={subtitleStyle}>{t('p.register_detail')}</Typography>
                        <DatePicker
                            disablePast
                            inputFormat="dd/MM/yyyy"
                            label={t('w.since')}
                            openTo="year"
                            views={['year', 'month', 'day']}
                            value={since}
                            onChange={(newValue) => {
                                setSince(newValue);
                                setDisableEdit(false);
                            }}
                            renderInput={(params) => (
                                <TextField sx={{ margin: 2, width: '40%', minWidth: 150, color: theme.palette.primary.dark }} {...params} />
                            )}
                        />
                        <DatePicker
                            disablePast
                            inputFormat="dd/MM/yyyy"
                            label={t('w.until')}
                            openTo="year"
                            views={['year', 'month', 'day']}
                            value={until}
                            onChange={(newValue) => {
                                setUntil(newValue);
                                setDisableEdit(false);
                            }}
                            renderInput={(params) => (
                                <TextField sx={{ margin: 2, width: '40%', minWidth: 150, color: theme.palette.primary.dark }} {...params} />
                            )}
                        />
                        <RegisterModalInputs rowInfo={props.rowInfo} readOnly={true} fieldsAndTraductions={fieldsAndTraductions} />
                    </Box>
                </Box>
                <Box sx={{ height: '6%', justifyContent: 'center' }}>
                    <FormControl sx={{ marginBottom: 4, marginRight: 4, float: 'right' }}>
                        <Button size="large" type="submit" onClick={handleModalClose} variant="contained">
                            {t('w.close')}
                        </Button>
                    </FormControl>
                    <FormControl sx={{ marginBottom: 4, marginRight: 4, float: 'right' }}>
                        <Button disabled={disableEdit} size="large" type="submit" onClick={handleEdit} variant="contained">
                            {t('w.apply')}
                        </Button>
                    </FormControl>
                </Box>
                <Dialog open={dialogOpen} onClose={handleDialogClose}>
                    <DialogTitle sx={{ fontSize: 18 }} id="alert-dialog-title">
                        {t('w.confirmation')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ fontSize: 16 }} id="alert-dialog-description">
                            {t('p.the_register') + ' ' + props.rowInfo.name + ' ' + t('p.will_be_modified_confirm')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            sx={{ margin: 2 }}
                            loading={editLoading}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained"
                            size="large"
                            onClick={handleModifyRegister}
                        >
                            {t('w.modify')}
                        </LoadingButton>
                        <Button size="large" onClick={handleDialogClose} sx={{ margin: 2 }} variant="outlined">
                            {t('w.discard')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Modal>
    );
}
RegisterModal.propTypes = {
    rowInfo: PropTypes.object.isRequired,
    handleListRegisters: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    modal: PropTypes.bool.isRequired
};
