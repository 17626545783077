function selectRegisterType(idRegisterType) {
    switch (idRegisterType) {
        case 1:
            return 'RegisterAffiliateSocial';
        case 2:
            return 'RegisterAffiliateAgreements';
        case 3:
            return 'RegisterDoctor';
        case 4:
            return 'RegisterInstitutions';
        case 5:
            return 'RegisterRetention';
        case 6:
            return 'RegisterAfipResponsable';
        case 7:
            return 'RegisterEntities';
        case 8:
            return 'RegisterProductImages';
        case 9:
            return 'RegisterIpc';
    }
}
export default selectRegisterType;
