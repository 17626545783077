import { useTheme } from '@emotion/react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

const RegisterModalInputs = (props) => {
    const theme = useTheme();

    const obtainsTraduction = (key) => {
        let itemWithTraduction = props.fieldsAndTraductions.find((item) => {
            return item.field == key;
        });
        return itemWithTraduction.trans;
    };

    const editable = (field) => {
        if (field == 'date_from' || field == 'date_to') return false;
        else return props.readOnly;
    };
    if (props.rowInfo) {
        var keys = Object.keys(props.rowInfo);
        const textFieldList = keys.map((key) => {
            if (key == 'creation_date' || key == 'creation_user' || key == 'modification_date' || key == 'modification_user') {
                return (
                    <TextField
                        id={key}
                        key={key}
                        size="small"
                        style={{ height: 38 }}
                        label={obtainsTraduction(key)}
                        value={props.rowInfo[key]}
                        InputProps={{
                            readOnly: editable(key)
                        }}
                        sx={{ margin: 2, width: '40%', minWidth: 150, color: theme.palette.primary.dark }}
                        InputLabelProps={{ shrink: true }}
                    />
                );
            }
        });
        return textFieldList;
    } else {
        return null;
    }
};

RegisterModalInputs.propTypes = {
    fieldsAndTraductions: PropTypes.array.isRequired,
    readOnly: PropTypes.bool.isRequired,
    rowInfo: PropTypes.object.isRequired
};

export default RegisterModalInputs;
