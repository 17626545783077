import { keycloakUrl } from 'api-request/urlConfig';
import { env } from '../../env';
const baseUrlKeycloak = env.REACT_APP_KEYCLOAK_SERVER;
const ChangeLanguageRequest = async (userId, language) => {
    const dataBody = { attributes: { language: language } };
    //const userId = JSON.parse(localStorage.getItem('user')).sub;
    let token = JSON.parse(localStorage.getItem('tokens'));
    const myAuthorizationHeader = `Bearer ${token.access_token}`;
    const url = baseUrlKeycloak + keycloakUrl + 'users/' + userId;
    try {
        const response = await fetch(url, {
            //mode: 'no-cors', // Este hay que sacarlo
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: myAuthorizationHeader
            },
            body: JSON.stringify(dataBody)
        });
        return response;
    } catch (error) {
        let response = { status: -1, text: 'Error de comunicación' };
        return response;
    }
};
export default ChangeLanguageRequest;
