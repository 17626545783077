import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';

// assets
import { IconMenu2 } from '@tabler/icons';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();

    return (
        <>
            <Box
                height={'2vw'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <IconButton
                    aria-label="open-menu"
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        transition: 'all .2s ease-in-out',
                        overflow: 'hidden',
                        mt: 1,
                        background: '#c8e4fb',
                        color: '#0277bd',
                        '&:hover': {
                            background: '#3378af',
                            color: '#c8e4fb'
                        }
                    }}
                    onClick={handleLeftDrawerToggle}
                    color="inherit"
                >
                    <IconMenu2 stroke={1.5} size="1.3rem" />
                </IconButton>

                <Box sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
            </Box>
            <Box sx={{ flexGrow: 2 }} />

            <ProfileSection />
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
