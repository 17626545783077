import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importing translation files

import translationEN from './locales/en/translation.json';
import translationES from './locales/es/translation.json';

//Creating object with the variables of imported translation files
const resources = {
    en: {
        translation: translationEN
    },
    es: {
        translation: translationES
    }
};

var defaultLanguage = () => {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user).language : 'en';
};

//i18N Initialization
i18n.use(initReactI18next).init({
    resources,
    lng: defaultLanguage(),
    keySeparator: false,
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
