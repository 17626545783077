import useAlert from 'contexts/alertProvider/useAlert.js';
import PropTypes from 'prop-types';

const { Snackbar, Alert, Slide } = require('@mui/material');

function TransitionRight(props) {
    return <Slide {...props} direction="left" />;
}

const Alerts = () => {
    const alert = useAlert();
    const aux = () => {
        return alert.clear();
    };

    return (
        <Snackbar
            open={alert.alert != 'none'}
            TransitionComponent={TransitionRight}
            autoHideDuration={6000}
            onClose={aux}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
        >
            <Alert severity={alert.alertSeverity} variant="filled" sx={{ width: '100%' }} onClose={aux}>
                {alert.alertText}
            </Alert>
        </Snackbar>
    );
};

Alerts.propTypes = {
    handleClose: PropTypes.func,
    visibleAlert: PropTypes.bool,
    severity: PropTypes.string,
    message: PropTypes.string
};

export default Alerts;
