// material-ui
import logoZetti from '../assets/images/logoZetti.png';
import { styled } from '@mui/material/styles';

// styles
const LogoStyle = styled('div')(({ theme }) => ({
    webkitTransform: 'scale(0.7)',
    transform: 'scale(0.7)',
    [theme.breakpoints.down('md')]: { marginLeft: '25%' }
}));

//import logo from 'assets/images/logo.svg';
// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    return (
        <LogoStyle>
            <div>
                <img src={logoZetti} alt="" />
            </div>
        </LogoStyle>
    );
};

export default Logo;
