import AnimateButton from 'ui-component/extended/AnimateButton';
import ChangeLanguageRequest from 'api-request/userSessionAdministration/changeLanguage';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SubCard from 'ui-component/cards/SubCard';
import { Drawer, Fab, FormControl, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Tooltip } from '@mui/material';
import { IconSettings } from '@tabler/icons';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { apiVersionRequest } from 'api-request/apiVersionRequest';
import Typography from '@mui/material/Typography';
import processResponse from 'api-request/processResponse.js';
import useAlert from 'contexts/alertProvider/useAlert.js';
import config from '../../config';

// ==============================|| LIVE CUSTOMIZATION ||============================== //

const Customization = () => {
    const theme = useTheme();
    const alert = useAlert();
    const { t, i18n } = useTranslation();
    const appVersion = config.appVersion;
    const appDate = config.appDate;
    const [apiVersion, setApiVersion] = useState('-');
    const [apiDate, setApiDate] = useState('-');

    const getVersion = async () => {
        let response = await apiVersionRequest();
        let ver = JSON.parse(await processResponse(response, alert, t('p.cannot_connect_api')));
        if (ver) {
            setApiVersion(ver.version);
            setApiDate(ver.date);
        }
    };

    const errorMesage = t('p.connect_server_failed');

    const handleChangeLanguageRequest = async (user, languageValue) => {
        let response = await ChangeLanguageRequest(user, languageValue);
        return await processResponse(response, alert, errorMesage);
    };
    const changeLanguageHandler = async (e) => {
        const languageValue = e.target.value;
        var user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            const response = await handleChangeLanguageRequest(user.sub, languageValue);
            if (response) {
                user.language = languageValue;
                localStorage.removeItem('user');
                localStorage.setItem('user', JSON.stringify(user));
                i18n.changeLanguage(languageValue);
            }
        } else {
            i18n.changeLanguage(languageValue);
        }
    };

    const activeLanguage = i18n.language;

    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen(!open);
        apiDate == '-' && getVersion();
    };

    return (
        <>
            <Tooltip title={t('w.settings')}>
                <Fab
                    component="div"
                    onClick={handleToggle}
                    size="medium"
                    variant="circular"
                    color="secondary"
                    sx={{
                        borderRadius: 0,
                        borderTopLeftRadius: '50%',
                        borderBottomLeftRadius: '50%',
                        borderTopRightRadius: '50%',
                        borderBottomRightRadius: '4px',
                        top: '25%',
                        position: 'fixed',
                        right: 10,
                        zIndex: theme.zIndex.speedDial
                    }}
                >
                    <AnimateButton type="rotate">
                        <IconButton color="inherit" size="large" disableRipple title="Customization-button">
                            <IconSettings />
                        </IconButton>
                    </AnimateButton>
                </Fab>
            </Tooltip>

            <Drawer
                anchor="right"
                onClose={handleToggle}
                open={open}
                PaperProps={{
                    sx: {
                        width: 280
                    }
                }}
            >
                <PerfectScrollbar component="div">
                    <Grid container spacing={gridSpacing} sx={{ p: 3 }}>
                        <Grid item xs={12}>
                            <SubCard title={t('p.language_selector')}>
                                <FormControl>
                                    <RadioGroup
                                        aria-label="language-selector"
                                        value={activeLanguage}
                                        onChange={changeLanguageHandler}
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel
                                            value="en"
                                            control={<Radio />}
                                            label={t('w.english')}
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 10 },
                                                '& .MuiFormControlLabel-label': { color: theme.palette.grey[900] }
                                            }}
                                        />
                                        <FormControlLabel
                                            value="es"
                                            control={<Radio />}
                                            label={t('w.spanish')}
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 10 },
                                                '& .MuiFormControlLabel-label': { color: theme.palette.grey[900] }
                                            }}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </SubCard>
                            <SubCard title={t('p.app_version')}>
                                <Typography sx={{ fontSize: 14 }} gutterBottom>
                                    {appVersion}
                                </Typography>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    {appDate}
                                </Typography>
                            </SubCard>
                            <SubCard title={t('p.api_version')}>
                                <Typography sx={{ fontSize: 14 }} gutterBottom>
                                    {apiVersion}
                                </Typography>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    {apiDate}
                                </Typography>
                            </SubCard>
                        </Grid>
                    </Grid>
                </PerfectScrollbar>
            </Drawer>
        </>
    );
};

export default Customization;
