import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import AuthProvider from 'Auth/AuthProvider';
import GlobalProvider from 'contexts/globalContext/GlobalProvider';
import SelectorRegisterProvider from 'contexts/selectorRegisterContext/SelectorRegisterProvider';
import ListingRecordsProvider from 'contexts/listRegistersRecords/ListingRecordsProvider';
import AlertProvider from 'contexts/alertProvider/alertProvider';
import Alerts from 'utils/alerts.js';
import { useEffect } from 'react';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    const path = window.location.href;

    useEffect(()=>{
        localStorage.setItem("lastPath", path.split('/#')[1]);
    },[path]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <CssBaseline />
                    <NavigationScroll>
                        <GlobalProvider>
                            <AuthProvider>
                                <SelectorRegisterProvider>
                                    <ListingRecordsProvider>
                                        <AlertProvider>
                                            <Alerts />
                                            <Routes />
                                        </AlertProvider>
                                    </ListingRecordsProvider>
                                </SelectorRegisterProvider>
                            </AuthProvider>
                        </GlobalProvider>
                    </NavigationScroll>
                </LocalizationProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
