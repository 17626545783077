import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const GlobalContext = createContext();

const GlobalProvider = ({ children }) => {
    const [serverErrorAlert, setServerErrorAlert] = useState(false);
    const contextValue = {
        serverErrorAlert,

        handleClose(state) {
            setServerErrorAlert(state);
        }
    };
    return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>;
};

GlobalProvider.propTypes = {
    children: PropTypes.element.isRequired
};

export default GlobalProvider;
